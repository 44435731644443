import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Spacing from '../../components/partials/Spacing'
import ModalImage from 'react-modal-image'
import { StaticImage } from 'gatsby-plugin-image'
import AboveFooterSection from '../../components/AboveFooterSection'

const vortexa = () => {
  const data = useStaticQuery(graphql`
    query VortexaSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "VortexaMSKOLD" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="overflow-hidden hero-margin">
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-lg-4 col-xl-5 col-12 justify-content-center align-self-center">
              <div className="mb-3">
                <StaticImage
                  className="w-30"
                  placeholder="tracedSVG"
                  src="../../resources/images/clients/vortexa/vortexa-with-color.png"
                  alt="Vortexa Logo"
                />
              </div>
              <div>
                <h1> How Vortexa makes Apache Kafka smooth sailing</h1>
                <div>
                  <h2 className="hero-description-dark pt-1 roboto">
                    Real-time analytics for logistics is no longer a
                    nice-to-have. It&apos;s essential. Vortexa explains how they
                    deliver a controlled view of their entire data landscape
                    with Lenses on MSK.
                  </h2>
                </div>
              </div>

              <div className="roll-button mt-3">
                <a className="text-white bg-red" href="/start/">
                  <span id="heroButton" data-hover="Try free">
                    Try free
                  </span>
                </a>
              </div>
            </div>

            <div className="col-lg-8 col-xl-7 col-12 tablet-margin-top">
              <StaticImage
                className="ml-4"
                placeholder="tracedSVG"
                src="../../resources/images/clients/vortexa/vortexa_hero.png"
                alt="Vortexa Hero"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-md-3 col-sm-12 mobile-margin-bottom mobile-margin-top">
              <div className="footer-color position-sticky rounded casestudy-container-left">
                <div className="px-3 py-1">
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    15%
                  </p>
                  <p>
                    <b>Working hours</b> saved on Apache Kafka management.
                  </p>
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    Takes minutes
                  </p>
                  <p>(Not days) to deploy to production.</p>
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    200%
                  </p>
                  <p>
                    Increase in Kafka users (both operators and application
                    developers).
                  </p>
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    90%
                  </p>
                  <p>
                    Reduction in AIS signal noise aided by QA done exclusively
                    in Lenses.io.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="casestudy-container-right">
                <p className="p-text">
                  Vortexa is an energy analytics provider. They offer the most
                  complete dataset for global refined products and crude oil
                  movements, supporting traders to formulate trading decisions
                  on the market.
                </p>
                <p className="p-text">
                  Their advanced real-time analytics service is built around an
                  Apache Kafka streaming data platform; made up of two clusters
                  and over 250 streams in each cluster. The uninterrupted flow
                  of data and fast delivery of new data services to their
                  customers is critical to their business.
                </p>
                <p className="h3-like-dark mb-1 mt-5 text-dark">
                  But to start with, Apache Kafka was a black box. And the team
                  struggled to stabilize the self-managed Kafka infrastructure.
                </p>

                <ul className="red-checked-list mobile-padding-reset">
                  <li>
                    <span className="pl-1">Debugging incidents took days</span>
                  </li>
                  <li>
                    <span className="pl-1">
                      The smallest mistake could bring down a whole cluster
                    </span>
                  </li>
                  <li>
                    <span className="pl-1">
                      Slowdowns getting into production
                    </span>
                  </li>
                  <li>
                    <span className="pl-1">
                      Specialist skills were difficult to come by These
                      fragilities and complexities in an all-important Kafka
                      infrastructure cost the team their weekends and ate into
                      their release schedule.
                    </span>
                  </li>
                </ul>

                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      While Kafka and Kafka Streams are extremely powerful
                      technologies, they are black-boxes. Lenses created
                      unprecedented visibility into the real-time data flows in
                      Vortexa’s Kafka infrastructure, helping to reduce
                      development cycle times and build confidence in R&D.
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Maksym Schipka, CTO</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>

                <p className="h3-like-dark mb-1 mt-5 text-dark">
                  From minutes to seconds to inspect messages
                </p>

                <p className="p-text">
                  To address this, the Vortexa team evaluated a number of
                  managed solutions, including Amazon Managed Streaming for
                  Apache Kafka (MSK), and found that MSK best met their
                  requirements and budget. Amazon MSK continuously monitors
                  cluster performance, is self-healing, and automatically
                  replaces unhealthy nodes without the need for downtime.
                  Vortexa used Lenses.io as their data operations portal, giving
                  them data observability and governance control for their
                  real-time applications that run across their MSK and
                  Kubernetes platform.
                </p>
                <p className="p-text">
                  Today, both engineering and operations use Lenses for insights
                  into the Kafka environment.
                </p>
                <p className="p-text">
                  Everyday tasks performed by developers that are essential for
                  creating high-quality services, such as inspecting messages on
                  a stream, understanding the profile of a topic, or verifying
                  topics have the right replication factor are now easy.
                </p>

                <p className="p-text">
                  Previously, such ad-hoc tasks took around five-to-ten minutes
                  and were achieved using a combination of different CLI tools
                  and open source UIs. Having a one-stop-shop for prototyping,
                  debugging and operations now brings that time down to seconds.
                </p>

                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      I’ve found the Lenses UI absolutely brilliant for creating
                      Kafka connectors. Lenses makes experimenting with
                      connectors almost trivial, it’s so fast to change config
                      and redeploy.
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Kit Burgess, Data Scientist</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>

                <br />
                <br />

                <ModalImage
                  className="w-75 py-2"
                  small="//images.ctfassets.net/tnuaj0t7r912/6jwM7NeBQ4sV5sxs771jaj/80eccd53d98d405609756f0e71c98210/ezgif.com-gif-maker__1_.jpg"
                  large="//images.ctfassets.net/tnuaj0t7r912/6jwM7NeBQ4sV5sxs771jaj/80eccd53d98d405609756f0e71c98210/ezgif.com-gif-maker__1_.jpg"
                  alt="Vortexa"
                />

                <p className="h3-like-dark mb-1 mt-5 text-dark">
                  Open monitoring with Amazon MSK & Lenses.io
                </p>
                <p className="p-text">
                  The health of the MSK infrastructure can be monitored within
                  external tools using the AWS Open Monitoring framework.
                  Vortexa uses Open Monitoring with Prometheus to access JMX
                  metrics generated by the MSK Brokers.
                </p>

                <p className="h3-like-dark mb-1 mt-5 text-dark">
                  Investigating consumer problems in minutes
                </p>
                <p className="p-text">
                  The availability of their Kafka platform is critical as
                  Vortexa’s customers make decisions worth millions of Euros on
                  the confidence of having correct and up-to-date information.
                </p>
                <p className="p-text">
                  Consumer problems, and in particular identifying the root
                  cause of performance degradation in a consumer group, was a
                  difficult challenge. With Lenses, they track consumer
                  performance and can instantly alert when a consumer has high
                  lag.
                </p>
                <p className="p-text">
                  Prior to Lenses, Vortexa had tried Confluent Control Center
                  but found it placed too big load on their brokers. They also
                  attempted to use various open-source Kafka tools but struggled
                  with limited functionality. Vortexa estimates that
                  troubleshooting a problem on an application can be done within
                  one minute using Lenses compared to hours using previous
                  tools.
                </p>

                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      We had problems with Confluent Control Center putting
                      significant strain on our Brokers and other open source
                      tools, such as Kafka Manager, lacked the rich features.
                      Lenses is one of the lightest on resources and gives us
                      the single tool that we need to make us productive.
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Jakub Korzeniowski, Head of Data Services</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>

                <p className="h2-like pt-3 pb-0 mb-1 text-dark">
                  Empowering engineers to build data flows
                </p>

                <p className="p-text">
                  Deploying new data pipelines that integrate data across their
                  different technologies, such as Elastic Search and Redis, can
                  now be built with no coding and deployed in minutes with
                  Lenses open source connectors and helm charts.
                </p>
                <p className="p-text">
                  Lenses allows Vortexa to first prototype a new data pipeline:
                  this empowers engineers to test their configuration in a UI
                  and explore and validate the data created in the stream.
                  Before, the team felt that the Kafka-Connect framework lacked
                  enterprise-readiness, so all the data integration connectors
                  were in-house developed applications.
                </p>
                <p className="p-text">
                  Today Vortexa has the enterprise-features on Lenses to adopt
                  Kafka Connect. They estimate that through their real-time data
                  portal, a new data flow can be developed and deployed into
                  production in minutes compared to one day of effort using
                  Kafka Connect connector minus Lenses and several days of
                  effort when maintaining their own connectors.
                </p>

                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      Lenses has streamlined prototyping and deploying Kafka
                      connectors to S3, Redis and Elasticsearch, by removing the
                      need to context-switch between configuration and
                      monitoring, and by feeding back error messages due to
                      misconfiguration. This has greatly reduced the development
                      time needed to complete these tasks.
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Richard Mathie, Platform Engineer</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>

                <p className="h2-like pt-3 pb-0 mb-1 text-dark">
                  Monitoring as a map for Developers & SREs
                </p>

                <p className="p-text">
                  Many of Vortexa’s apps are based on the Kafka Streams
                  framework. By integrating the Lenses topology client into
                  their Kafka Streams applications, Vortexa is able to see a
                  real-time topological view of not only their data landscape
                  (including their flows to Redis and Elastic Search) but also
                  inside their Kafka Streams applications.
                </p>
                <p className="p-text">
                  This allows their Developers and SREs to better understand the
                  streams and identify where there are bottlenecks, such as low
                  message rate throughput as well as drilling-down to see
                  consumer group lag.
                </p>
                <p className="p-text">
                  Energy market players rely on Vortexa to provide reliable
                  real-time data so they can make smarter shipping and trading
                  decisions. This all hinges not only on possessing the
                  streaming power of Apache Kafka; but in harnessing it by
                  making their data available to the right people - thereby
                  shortening the development cycle and speeding up
                  time-to-market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'The Data Platform for Open Source technologies'}
        ctaText={'Start'}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default vortexa
